<template>
    <Dialog header="Group Invites" v-model:visible="display" :modal="true" :closable="true" class="group-invites-modal">
        <GroupInvitesList />
    </Dialog>
</template>

<script>

import Dialog from 'primevue/dialog';

import GroupInvitesList from '../GroupInvitesList.vue';

export default {
    name: 'GroupInvitesModal',
    components: {
        Dialog,
        GroupInvitesList,
    },
    data() {
        return {
            display: false,
        }
    },

    methods: {
        open() {
            this.display = true;
        },
    }
}
</script>

<style>
.group-invites-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 600px;
    height: 380px;
    border-radius: 16px;
}

.group-invites-modal .p-dialog-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.group-invites-modal .p-dialog-content {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow: hidden;
    padding: 0px;
    height: 100%;
}
</style>
<style scoped>

</style>