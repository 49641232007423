import { createAvatar } from '@dicebear/core';
import * as style from '@dicebear/initials';

export function generateGroupAvatar(name) {
   // console.log(name);
    return name == 'INVRS Investment Group' ? '/Invrs-Symbol-Blue.png' : createAvatar(style, {
        seed: name
    }).toString();
}

export const SELECTED_FEED = {
    'MY_GROUPS': 'My Groups',
    'DISCOVER': 'Discover'
}

export const GROUP_TYPE = {
    1: 'Community',
    2: 'Team'
}


export const PREMIUM_GROUP_SUBS = [
        {id:13, name: "Premium Small Group", period: "Monthly", description: "For groups with 100 members or less."}, 
        {id:14, name: "Premium Medium Group", period: "Monthly", description: "For groups with 1000 members or less."}, 
        {id:15, name: "Premium Large Group", period: "Monthly", description: "For groups with 1000 members or more."}, 
        {id:16, name: "Premium Small Group", period: "Yearly", description: "For groups with 100 members or less."}, 
        {id:17, name: "Premium Medium Group", period: "Yearly", description: "For groups with 1000 members or less."}, 
        {id:18, name: "Premium Large Group", period: "Yearly", description: "For groups with 1000 members or more."}, 
        {id:19, name: "Premium Team", period: "Monthly", description: "Premium Team Subscription"}, 
        {id:20, name: "Premium Team",  period: "Yearly",description: "Premium Team Subscription"}
    ];
export const PREMIUM_SMALL_GROUP_SUBS = [ 
    {id:13, name: "Premium Small Group", period: "Monthly", description: "For groups with 100 members or less."},
    {id:16, name: "Premium Small Group", period: "Yearly", description: "For groups with 100 members or less."}
];
export const PREMIUM_MEDIUM_GROUP_SUBS = [
    {id:14, name: "Premium Medium Group", period: "Monthly", description: "For groups with 1000 members or less."}, 
    {id:17, name: "Premium Medium Group", period: "Yearly", description: "For groups with 1000 members or less."}
];
export const PREMIUM_LARGE_GROUP_SUBS = [
    {id:15, name: "Premium Large Group", period: "Monthly", description: "For groups with 1000 members or more."},
    {id:18, name: "Premium Large Group", period: "Yearly", description: "For groups with 1000 members or more."}
];
export const PREMIUM_TEAM_SUBS = [
    {id:19, name: "Premium Team",  period: "Monthly",description: "Premium Team Subscription"},
    {id:20, name: "Premium Team",  period: "Yearly",description: "Premium Team Subscription"}
];

export function isINVRSGroup(groupId) {
    return groupId == 1;
}

export function isPremiumSmallPlan(plan) {
    return PREMIUM_SMALL_GROUP_SUBS.some((sub => sub.id == plan.id));
}

export function isPremiumMediumPlan(plan) {
    return PREMIUM_MEDIUM_GROUP_SUBS.some((sub => sub.id == plan.id));
}

export function isPremiumLargePlan(plan) {
    return PREMIUM_LARGE_GROUP_SUBS.some((sub => sub.id == plan.id));
}

export function isPremiumTeamPlan(plan) {
    return PREMIUM_TEAM_SUBS.some((sub => sub.id == plan.id));
}

export function hasPremiumGroupSubscription(subscriptionId){
    return PREMIUM_GROUP_SUBS.some((sub => sub.id == subscriptionId));
}

export function hasPremiumGroupPlan(group){
    return hasPremiumGroupSubscription(group?.subscriptionId);
}

export function isBasicPlan(plan) {
    return !plan || plan.id == 0;
}

export function getPlan(planType, selectedFrequency) {
    let plans = null;
    if(planType == 'small') {
       plans = PREMIUM_SMALL_GROUP_SUBS;
    }else if( planType == 'medium') {
        plans = PREMIUM_MEDIUM_GROUP_SUBS;
    }
    else if( planType == 'large') {
        plans = PREMIUM_LARGE_GROUP_SUBS;
    }
    else {
        plans = PREMIUM_TEAM_SUBS;
    }

    if( selectedFrequency == 'Monthly'){
        return plans[0];
    }
    else {
        return plans[1];
    }
}



export function getGroupSubscription(subscriptionId){
    let sub = {id:0, name:"Basic"};
    for( var i = 0; i < PREMIUM_GROUP_SUBS.length; ++i ) {
        //console.log("comparing "+ subscriptionId + ' with ', PREMIUM_GROUP_SUBS[i]);
        if(  PREMIUM_GROUP_SUBS[i].id == subscriptionId){
            sub =  PREMIUM_GROUP_SUBS[i];
            break;
        }
    }
    //console.log("subscription is ", sub);
    return sub;
}

export const GroupPlanUtils = {
    getGroupSubscription: getGroupSubscription,
    hasPremiumGroupSubscription: hasPremiumGroupSubscription,
    isPremiumTeamPlan: isPremiumTeamPlan,
    isPremiumLargePlan: isPremiumLargePlan,
    isPremiumMediumPlan: isPremiumMediumPlan,
    isPremiumSmallPlan: isPremiumSmallPlan,
    isINVRSGroup: isINVRSGroup,
    isBasicPlan: isBasicPlan,
    getPlan: getPlan,
    hasPremiumGroupPlan: hasPremiumGroupPlan,

}

export function groupSubInfo(number, type, isPrivate) {
    const memberText = number === 1 ? 'Member' : 'Members';
    const groupType = (isPrivate ? "Private "+ GROUP_TYPE[type] : "Public "+ GROUP_TYPE[type]);
    return `${number} ${memberText} | ${groupType}`;
}

export function excludeDefaultInvrsGroup(group) {
    return group.groupId !== 1;
}