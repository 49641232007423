<template>
    <div class='group-list-container'>
        <div class='group-list-container card'>
            <SearchBar ref='groupListSearchBar' @search-input="searchInput" @loading="onLoading" placeholderText="Search for a group" :delay="0"/>
        </div>
        <div>
            
            <infinite-scroll @infinite-scroll="loadMoreGroups" message="" :noResult="noMoreGroups" :style='scrollStyle' :contentStyle='scrollContentStyle'>
                <GroupListItem v-if="isMobile() && feedType != SELECTED_FEED['DISCOVER'] && groupInvrs " :key='groupInvrs.id' :avatar="groupInvrs.avatar || ''" :headerText='groupInvrs.name'
                    :subText='groupInvrs.bio ? groupInvrs.bio : ""' :subText2='groupSubInfo(groupInvrs.memberCount, groupInvrs.groupTypeId, groupInvrs.isPrivate)' 
                    :groupId='groupInvrs.groupId' >
                    <template #actions>
                        <Button class="join-group-button" icon="pi pi-chevron-right"  @click='onGroupBlockClick(groupInvrs)' @touchstart="onGroupBlockClick(groupInvrs)"/>
                        <img v-if="group.subscriptionRequired" src="/assets/images/subRequired.png" style="width:25px;height:25px;"/>
                    </template>
                </GroupListItem>
                <template v-if="isMobile()">
                    <GroupListItem v-for="group in feedGroups" :key='group.id' :avatar="group.avatar || ''" :headerText='group.name'
                    :subText='group.bio ? group.bio : ""' :subText2='groupSubInfo(group.memberCount, group.groupTypeId, group.isPrivate)'
                    :groupId='group.groupId'>
                        <template #actions>
                            <Button class="join-group-button" icon="pi pi-chevron-right"  @click='onGroupBlockClick(group)' @touchstart="onGroupBlockClick(group)"/>
                             <img v-if="group.educational" src="/assets/images/eduAccount.png" style="width:30px;height:30px;"/>
                             <img v-else-if="group.coursesEnabled" src="/assets/images/books.png" style="width:30px;height:30px;"/>
                             <img v-if="group.subscriptionRequired" src="/assets/images/subRequired.png" style="width:25px;height:25px;"/>
                        </template>
                    </GroupListItem>
                </template>
                <template v-else>
                    <GroupListItem v-for="group in feedGroups" :key='group.id' :avatar="group.avatar || ''" :headerText='group.name'
                    :subText='group.bio ? group.bio : ""' :subText2='groupSubInfo(group.memberCount, group.groupTypeId, group.isPrivate)'
                     @click='onGroupBlockClick(group)' >
                     <template #actions>
                        <img v-if="group.educational" src="/assets/images/eduAccount.png" style="width:30px;height:30px;"
                            v-tooltip.bottom="'This is an approved educational group that includes courses that have been reviewed by INVRS.'"/>
                        <img v-else-if="group.coursesEnabled" src="/assets/images/books.png" style="width:30px;height:30px;"
                            v-tooltip.bottom="'This group includes educational courses as part of their membership.'"/>
                        <img v-if="group.subscriptionRequired" src="/assets/images/subRequired.png" style="width:25px;height:25px;"
                        v-tooltip.bottom="subRequiredText"/>
                     </template>
                    </GroupListItem>
                    <LoadingSpinner :showLoading="initLoading" :minHeight="initLoaderMinHeight"/>
                </template>
            </infinite-scroll>
            
        </div>
    </div>
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner.vue';
import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue';
import GroupListItem from './GroupListItem.vue';
import SearchBar from '../query/SearchBar.vue';

import GroupService from '../../service/GroupService';
import BrowserUtils from '../../utilities/BrowserUtils';
import { USER_TYPES } from '../../common/constants';
import {SELECTED_FEED, groupSubInfo} from '.';

import EventBus from '../../event-bus';

const LOAD_GROUPS_LIMIT = 30;

export default {
    name: 'GroupSubFeed',
    emits: ['enter-group'],
    props: {
        feedType: {
            type: String,
            required: true
        }
    },
    components: {
        LoadingSpinner, GroupListItem, InfiniteScroll, SearchBar
    },


    data() {
        return {
            //CONSTANTS
            SELECTED_FEED,
            USER_TYPES,
            LOAD_GROUPS_LIMIT,
            subRequiredText : "Subscription Required:  To join this group you'll need to purchase a group membership.",
            currentText: '',
            feedGroups: [],
            loading: false,
            initLoading: false,
            noMoreGroups: false,
            groupInvrs: null,
            excludedGroupCount: 0,
        }
    },
    computed: {
        scrollStyle() {
            return BrowserUtils.isMobile() ? "width: 100%; height: calc(100vh - 110px);" : "width: 100%; height: calc(100vh - 198px);";
        },
        scrollContentStyle() {
            return BrowserUtils.isMobile() ? "width: 100%; height: calc(100vh - 110px);" : "display: grid; grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); gap: 1rem; padding: 1px 1px 100px;";
        },
        initLoaderMinHeight() {
            return BrowserUtils.isMobile() ? "calc(100vh - 110px)" : "200px";
        }
    },

    created() {
        EventBus.off('refresh-group-list');
        EventBus.on('refresh-group-list', () => {
            this.$refs.groupListSearchBar?.clearInput();
            this.initLoadGroups();
        });
    },
    mounted() {
        this.initLoadGroups();

        if(this.isMobile()){
            GroupService.getGroup(1).then(resp=> {
                if( resp.data.status == 'success'){
                    //console.log(resp.data.group);
                    this.groupInvrs = resp.data.group;
                }
            });
        }
    },

    methods: {
        groupSubInfo,

        isMobile() {
            return BrowserUtils.isMobile();
        },

        isTeamGroup(group) {
            return group.groupTypeId == 2;
        },

        onLoading() {
            this.initLoading = true;
            this.feedGroups = [];
        },

        searchInput(val) {
            this.currentText = val;
            this.feedGroups = [];
            this.loadGroups(LOAD_GROUPS_LIMIT, 0, val)
        },

        initLoadGroups() {
            // console.log("initLoadGroups");
            this.currentText = '';
            this.feedGroups = [];
            this.initLoading = true;
            this.loadGroups(LOAD_GROUPS_LIMIT, 0, '');
        },

        loadGroups(limit, offset, query) {
            this.excludedGroupCount == offset == 0 ? 0 : this.excludedGroupCount;

            const concatGroups = (groups) => {
                let results = [];

                if (groups.length > 0) {
                    results = this.feedGroups.concat(groups);

                    this.noMoreGroups = false;
                } else {
                    this.noMoreGroups = true;
                }

                return results;
            }
            this.initLoading = true;
            // console.log("getMyGroups", offset, limit, query, this.noMoreGroups);
            if (this.feedType === SELECTED_FEED['MY_GROUPS']) {
                GroupService.getMyGroups(offset, limit, query).then((resp => {
                    if( resp.data.status == "success") {
                        
                        
                        this.loading = false;

                        const groups = resp.data.groups.filter((group) => {
                            if(group.groupId != 1){
                                return true;
                            } else {
                                this.excludedGroupCount++;
                                return false;
                            }
                        });
                        this.initLoading = false;

                        if (groups.length > 0) {
                            this.feedGroups = concatGroups(groups);
                        }
                        
                    }
                }));
            }
            else {
                GroupService.getPopularGroups(offset, limit, query).then((resp => {
                    if( resp.data.status == "success") {

                       
                        this.loading = false;

                        const groups = resp.data.groups.filter((group) => {
                            if(group.groupId != 1){
                                return true;
                            } else {
                                this.excludedGroupCount++;
                                return false;
                            }
                        });
                         this.initLoading = false;
                        if (groups.length > 0) {
                            this.feedGroups = concatGroups(groups);
                        }
                       

                        //if (groups.length > 0) {
                            // const values = groups.reduce((total, group) => {
                            //     if (group.bio) {
                            //         total.push(GroupService.getGroupJoinRequest(group.groupId));
                            //     }

                            //     return total;
                            // }, [])
                            
                            // Promise.allSettled(values).then(values => {
                            //     this.feedGroups = groups.map((group) => {
                            //         if (group.isPrivate) {
                            //             group['hasPendingRequest'] = false;

                            //             // values.forEach((value) => {
                            //             //     const val = value.value;
                            //             //     const req = val.data.request;

                            //             //     if( req ){
                            //             //         if (group.groupId === req.groupId) {
                            //             //             if (!req.retiredDate && !req.accepted) {
                            //             //                 group.hasPendingRequest = true;
                            //             //             }
                            //             //         }
                            //             //     }   
                            //             // })
                            //         }

                            //         return group;
                            //     })
                            // })
                        //}
                    }
                }));
            }
        },

        async loadMoreGroups() {
            // console.log("loadMoreGroups", this.feedGroups.length + this.excludedGroupCount, LOAD_GROUPS_LIMIT);
            if (!this.loading && this.feedGroups.length + this.excludedGroupCount >= LOAD_GROUPS_LIMIT) {
                this.loading = true;

                if (!this.noMoreGroups) {
                    this.loadGroups(LOAD_GROUPS_LIMIT, this.feedGroups.length + this.excludedGroupCount, this.currentText);
                } else {
                    this.loading = false;
                }
            }
        },

        onGroupBlockClick(group) {
            
            this.$emit('enter-group', group);
        },

        joinGroup(group) {
            const joinGroupFunc = () => {
                if (!group.isPrivate) {
                    GroupService.joinGroup(group).then(resp => {
                        if( resp.data.status === "success") {
                            resp.data.group.isMember = true;
                            this.initLoadGroups();
                            this.$store.commit('MARK_CHANNELS_STALE');
                            this.$toast.add({ severity: 'success', summary: `You have now joined ${group.name}!`, life: 2500, });
                            EventBus.emit('refresh-app-group-menu');

                            if(this.isTeamGroup(group)) {
                                EventBus.emit('track-joining-team');
                            } else {
                                EventBus.emit('track-joining-group');
                            }
                        }
                        else {
                            console.log("error joinging group "+ resp.data.message);
                            if(resp.data.message && resp.data.message.startsWith("Team members limit is")){
                                this.$toast.add({severity:'info', summary: "Oops! This team is full with 20 members. Why don't you start your own?", detail:'', life: 4000, group: 'center'});
                            }
                            else if( resp.data.message == 'Not Authorized') {
                                this.$toast.add({ severity: 'error', summary: `You are not authorized to join this group.`, life: 2500, });
                            }
                            else {
                                this.$toast.add({ severity: 'error', summary: `An unexpected error occurred while joining group.  Please try again or contact support@invrs.com.`, life: 2500, });
                            }
                        }
                    })
                } else {
                    GroupService.requestToJoin(group.groupId).then(resp=> {
                        if( resp.data.status == "success"){
                            group.hasPendingRequest = true;
                            this.$toast.add({ severity: 'success', summary: `A request has been sent to the group administrator for ${group.name}.   You will be notified when your request is accepted.`, life: 2500, });
                        }
                    });
                }
            }

            // if(this.selectedGroup && this.isTeamGroup() && this.selectedGroup.groupPortfoliosEnabled){
            //     EventBus.emit("open-join-group-modal", { joinGroupFunc:joinGroupFunc });
            // } else {
                joinGroupFunc();
            // }
        },


    }
}
</script>

<style scoped>
*:not(.pi), ::v-deep(.p-component) {
    font-family: 'Trebuchet MS', 'Verdana';
}

.group-list-container.card {
    padding: 16px 0px 0px;
    margin-bottom: 16px;
}

.p-scrollpanel ::v-deep(.p-scrollpanel-content) {
    padding: 0px 0px 0px;
}

::v-deep(.list-item-container) {
    border-bottom: 1px solid #BFBFBF;
}
::v-deep(.list-item-container:last-child) {
    border-bottom: none;
}


::v-deep(.join-group-button) {
    margin-left: auto;
    background: #FFFFFF;
    border: 1px solid #33CC99;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    color: #32364e;
    padding: 8px 12px;
}
::v-deep(.join-group-button:hover) {
    background: #33CC99;
    border-color: #33CC99;
    color: #FFFFFF;
}
::v-deep(.join-group-button:focus) {
    border-color: #33CC99;
}

@media (max-width: 760px) {
    .card {
        border-radius: 0px;
    }

    .p-scrollpanel ::v-deep(.p-scrollpanel-content) {
        height: calc(100% - 60px);
    }

    .group-list-container.card {
        margin-bottom: 0px;
    }
}
</style>