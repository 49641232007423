<template>
    <Dialog :header='headerText' v-model:visible="display" :modal="true" :closable="true" :class="`group-signup-process-modal-${step}`" @show='onShown'>
        
        <div class='content-row' v-if='step === 0'>
            <div class='input-container'>
                <InputText :class="{'name-group-input': true, 'input-error': v$.groupName.$error}" type="text" v-model="groupName"  placeholder="Enter your group name here..."
                @input="resetError"/>

                <template v-for="error of v$.groupName.$errors" :key="error.$uid">
                    <span class="error-message" v-if="!error.$pending">{{ error.$message }}</span>
                </template>
            </div>
        </div>
        <div class='content-row' v-else>
            <div class="content-column" @click="selectProcessor('left')">
                <span class="material-icons-outlined groups-icon">groups</span>
                <span class='column-header-text'>{{columnContent('left')['header']}}</span>
                <span class='column-sub-text'>{{columnContent('left')['subText']}}</span>
                <i class="pi pi-check-circle" v-show="step === 1 ? groupType === 'Community' : communityPrivacy === 'Public'" />
            </div>
            <div :class="{'content-column': true, 'disabled': columnContent('right')['disabled'] ? true : false}" @click="selectProcessor('right')">
                <span class="material-icons-outlined groups-icon">groups</span>
                <span class='column-header-text'>{{columnContent('right')['header']}}</span>
                <span class='column-sub-text'>{{columnContent('right')['subText']}}</span>
                <i class="pi pi-check-circle" v-show="step === 1 ? groupType === 'Team' : communityPrivacy === 'Private'" />
            </div>
            <div v-if="isAdmin() && step === 1" :class="{'content-column': true, 'disabled': columnContent('far-right')['disabled'] ? true : false}" @click="selectProcessor('far-right')">
                <span class="material-icons-outlined groups-icon">groups</span>
                <span class='column-header-text'>{{columnContent('far-right')['header']}}</span>
                <span class='column-sub-text'>{{columnContent('far-right')['subText']}}</span>
                <i class="pi pi-check-circle" v-show="groupType === 'Educational'" />
            </div>
        </div>

        <template #footer>
            <Button label="Back" class='back-button' @click='previousStep'/>
            <Button :label='nextButtonLabel' class='next-button' @click='nextStep' :disabled="nextStepDisabled" :loading="loading"/>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';

import GroupService from '../../../service/GroupService';
//import CustomValidationUtils from '../../../utilities/CustomValidationUtils';

import useVuelidate from '@vuelidate/core';
import { helpers, maxLength, minLength } from '@vuelidate/validators';
import EventBus from '../../../event-bus';
import { mapState } from 'vuex';

import { createAvatar } from '@dicebear/core';

import { rings} from '@dicebear/collection';

import UserUtils from '../../../utilities/UserUtils';


const LEFT_COL_CONTENT = {
    1: {
        'header': 'Community',
        'subText': 'A larger group where members can connect with like-minded investors, share research and analysis and talk about specific topics.  '
        + 'Features include: aggregate and collaborative portfolios, competition hosting and ability to create courses and other monetizable products.',
        'value': 'Community'
    },
    2: {
        'header': 'Public',
        'subText': 'Anyone can join a public group, and everyone can see the content posted in it. Admins can manage and moderate their community. Public groups cannot charge for membership.',
        'value': 'Public'
    },
   
}

const RIGHT_COL_CONTENT = {
    1: {
        'header': 'Team',
        'subText': "Limited to 20 members in size.  Create your team and invite your investment friends to join. Enjoy private chats and feeds. Connect your portfolios and get an aggregated view of everyone's holdings. Compete against other teams.",
        'value': 'Team',
        'disabled': false
    },
    2: {
        'header': 'Private',
        'subText': 'Private groups are by invitation only, by approved request to join, or by purchase of a subscription.  Only members of the group can see the content.  Private groups can charge for membership.',
        'value': 'Private',
        'disabled': false,
    }
}

const FAR_RIGHT_COL_CONTENT = {
    1: {
        'header': 'Educational',
        'subText': 'An Educational group providing courses to its members, either privately or for a fee.',
        'value': 'Educational'
    }
}

export default {
    name: 'GroupSignupProcess',
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            // CONSTANTS,
            LEFT_COL_CONTENT,
            RIGHT_COL_CONTENT,
            FAR_RIGHT_COL_CONTENT,

            display: false,
            step: 0,
            groupName: '',
            groupType: null,
            communityPrivacy: null,
            visible: true,
            loading: false
        }
    },
    components: {
        Dialog, InputText
    },

    mounted() {
        //console.log("GroupSignupProcess mounted");
    },

    computed: {
        ...mapState(['users']),

        headerText() {
            const HEADER_TEXT_MAP = {
                0: 'Name your group',
                1: 'Choose your group type',
                2: 'Choose group privacy'
            }

            return HEADER_TEXT_MAP[this.step];
        },

        nextButtonLabel() {
            if (this.step === 0) {
                return 'Next';
            } else {
                if (this.step === 1) {
                    return 'Next';
                } else {
                    return 'Finish';
                }
            }
        },
    
        nextStepDisabled() {
            let value = null;

            if (this.step === 0 ) {
                value = !this.groupName ? true : false
            } else if (this.step === 1) {
                value = !this.groupType ? true : false;
            } else if (this.step === 2) {
                value = !this.communityPrivacy ? true: false;
            } 

            return value;
        }
    },

    methods: {
        isAdmin() {
            return UserUtils.userIsAdmin();
        },

        async submit() {
            let isCommunityPrivate = false;
            this.loading = true;

            if (this.communityPrivacy === 'Private') {
                isCommunityPrivate = true;
            } 

           const avatar = createAvatar(rings, {
                seed: this.groupName+Math.random(),
                
                });
            const png = await avatar.png();
            const avatarDataUri = await png.toDataUri();
            GroupService.createGroup(this.groupName, this.createGroupUserName(this.groupName),this.groupType, isCommunityPrivate, this.visible, [],null, avatarDataUri).then((resp) => {
                this.loading = false;
                if (resp.data.status === 'success') {
                    this.display = false;
                    this.$toast.add({ severity: 'success', summary: `Group creation successful!`, life: 2500, group: 'center'});
                    this.$store.commit('MARK_CHANNELS_STALE');
                    let group = resp.data.group;

                    GroupService.generateGroupInviteLink(group.groupId, null); // Automatically create invite link with no expiry date after successful creation

                    EventBus.emit('refresh-group-list');
                    EventBus.emit('refresh-app-group-menu');
                    if( this.groupType == 'Educational') {
                        EventBus.emit('refresh-education-groups');
                    }

                    if(this.groupType == 'Community'){
                        EventBus.emit('track-creating-group', isCommunityPrivate);
                    } else {
                        EventBus.emit('track-creating-team', isCommunityPrivate);
                    }
                    
                }else if( resp.data.message === 'Name Taken') {
                    console.log("group name taken")
                    this.$toast.add({ severity: 'error', summary: `Group name taken, please choose another name for your group.`, life: 4500,group: 'center' });
                    
                
                } else {
                    console.error('error creating group ', + resp.data.message);
                    this.$toast.add({ severity: 'error', summary: `There was an error creating your group.  Please try again later.  If the problem presist please contact support@invrs.com`, life: 4500,group: 'center' });
                }
            })
        },

        createGroupUserName(groupName) {
            let groupUserName = groupName.replaceAll(" ", "_");
            return groupUserName;
        },

        columnContent(side) {
            if (side === 'left') {
                return LEFT_COL_CONTENT[this.step];
            } else if( side == 'right') {
                return RIGHT_COL_CONTENT[this.step];
            } else {
                return FAR_RIGHT_COL_CONTENT[this.step];
            }
        },

        open() {
            this.display = true;
        },
        isOpened() {
            return this.display;
        },
        onShown() {
            this.step = 0;
            this.groupName = '';
            this.groupType = null;
            this.communityPrivacy = null;
        },

        nextStep() {
            if (this.step === 0) {
                this.v$.groupName.$touch();

                if (this.v$.groupName.$errors.length == 0) {
                    this.step++;
                }
            } else {
                if (this.step === 1) {
                    this.step++;
                } else {
                    this.submit();
                }
            }
        },
        previousStep() {
            if (this.step === 0) {
                this.display = false;
            } else {
                this.step--;
            }
        },
        selectProcessor(side) {
            if (this.step === 1) {
                if (side === 'left'){
                    this.groupType = 'Community';
                }
                else if( side === 'right') {
                    this.groupType = 'Team';
                }
                else {
                    this.groupType = 'Educational';
                }
                
            } else { // For step - 2 Choosing community privacy
                this.communityPrivacy = side === 'left' ? 'Public' : 'Private';
            }
        },

        resetError() {
            this.v$.groupName.$reset();
        },
    },

    validations() {
        if(this.isAdmin()) {
             return {
                groupName: {
                    maxLength: helpers.withMessage('Must be 50 characters or less.', maxLength(100)),
                    minLength: minLength(6)
                }
             }
        }
        else {
            return {
                groupName: {
                    maxLength: helpers.withMessage('Must be 50 characters or less.', maxLength(100)),
                    minLength: minLength(6),
                    //onlySpacesHyphensUnderscores: helpers.withMessage('Should not contain any special characters. (Spaces, hyphens, Parentheses and underscores are allowed)', CustomValidationUtils.onlySpacesHyphensUnderscoresParentheses),
                    //noEndSpacesAndOneSpaceInBetween: helpers.withMessage('No spaces (spaces, hypens, and underscores) at either end, and only one space between each letter.', CustomValidationUtils.noEndSpacesAndOneSpaceInBetween)
                }
            }
        }
    }
}
</script>

<style>
.group-signup-process-modal-0 {
    width: 470px;
}
.group-signup-process-modal-1,
.group-signup-process-modal-2 {
    width: 728px;
}

[class*=group-signup-process-modal] .p-dialog-header {
    padding-bottom: 0px;
} 

[class*=group-signup-process-modal] .p-dialog-content {
    padding: 32px 16px;
}

[class*=group-signup-process-modal] .p-dialog-footer {
    display: flex;
    justify-content: center;
    width: 100%;
}
</style>
<style scoped>
::v-deep(*:not([class*=material-icons], .pi)),
*:not([class*=material-icons], .pi) {
    font-family: 'Trebuchet MS', 'Verdana';
}

/* Shaking animation */
@keyframes shakeError {
    0% {
        transform: translateX(0);
    }
    15% {
        transform: translateX(0.375rem);
    }
    30% {
        transform: translateX(-0.375rem);
    }
    45% {
        transform: translateX(0.375rem);
    }
    60% {
        transform: translateX(-0.375rem);
    }
    75% {
        transform: translateX(0.375rem);
    }
    90% {
        transform: translateX(-0.375rem);
    }
    100% {
        transform: translateX(0);
    }
}

.input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

::v-deep(.pi-check-circle) {
    font-size: 20px;
    color: #33CC99;
}

::v-deep(.p-button) {
    padding: 8px 16px;
    border-radius: 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}
.p-button:focus {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}

[class*=group-signup-process-modal] .p-dialog-content .content-row {
    display: flex;
    /* align-items: center; */
}

[class*=group-signup-process-modal] .p-dialog-content .content-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    width: 50%;
}
[class*=group-signup-process-modal] .p-dialog-content .content-column.disabled {
    pointer-events: none;
}
[class*=group-signup-process-modal] .p-dialog-content .content-column:hover {
    cursor: pointer;
    transition: all .6s;
    background: rgba(0, 0, 0, 0.12);
    border-radius: 16px;
}

[class*=group-signup-process-modal] .p-dialog-content [class*=material-icons] {
    color: #693BF5;
    margin-bottom: 24px;
}
[class*=group-signup-process-modal] .p-dialog-content .p-inputtext {
    border-radius: 8px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 40px;
    padding: 8px;
    flex: 1;
    color: #32364e;
    font-size: 16px;
	width: 100%;
}
[class*=group-signup-process-modal] .p-dialog-content .p-inputtext:hover {
    border-color: #33CC99;
}
.input-error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
}
[class*=group-signup-process-modal] .p-dialog-content .input-error.p-inputtext:hover,
[class*=group-signup-process-modal] .p-dialog-content .input-error .p-inputtext:enabled:focus,
[class*=group-signup-process-modal] .p-dialog-content .input-error.p-inputtext {
    border-color: #e63e3e;
}
.error-message:nth-child(2) {
    padding-top: 10px;
}
.error-message {
    color: #e63e3e;
    padding-left: 10px;
}


[class*=group-signup-process-modal] .p-dialog-content .column-header-text {
    color: black;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
}
[class*=group-signup-process-modal] .p-dialog-content .column-sub-text {
    color: #BFBFBF;
    font-size: 16px;
    text-align: center;
    margin-bottom: 16px;
}

[class*=group-signup-process-modal] .p-dialog-footer button {
    width: 50%;
    margin: 0px;
}

[class*=group-signup-process-modal] .p-dialog-footer .back-button {
    border: 1px solid #32364e;
    background: #FFFFFF;
    color: #32364e;
    margin-right: 24px;
}
[class*=group-signup-process-modal] .p-dialog-footer .back-button:hover {
    border-color: #32364e;
    color: #FFFFFF;
    background: #32364e;
}
[class*=group-signup-process-modal] .p-dialog-footer .back-button:focus {
    border-color: #32364e;
}
[class*=group-signup-process-modal] .p-dialog-footer .next-button {
    border: 1px solid #33CC99;
    background: #33CC99;
    color: #FFFFFF;
}
[class*=group-signup-process-modal] .p-dialog-footer .next-button:hover {
    background: #00C083;
    border-color: #00C083;
}
[class*=group-signup-process-modal] .p-dialog-footer .next-button:focus {
    border-color: #33CC99;
}

</style>