<template>
    <div v-if='!browserUtils.isMobile()'>
        <div class='group-home-right-panel'>
            <PillMenuBar :key="pillMenuBarKey" :navigationItems="myGroupsDiscoverMenuItems" headerType="sub" class='my-groups-discover-header-menu'>
            <template #left>
                 <Button class='green-button__primary p-button-rounded' label=' What are Groups?' icon="pi pi-video" iconPos="right" @click='showVideo' />
            </template>
            <template #custom>
                <!-- <Button :badge="inviteCountBadge" badgeClass="invite-count-badge" class='p-button-rounded blue-button group-invite-button' 
                    label="Group Invites" :disabled="invitesHeaderDisabled" @click='openGroupInvitesModal'/> -->
              
                <Button class='green-button__primary p-button-rounded' label='Create New Group' icon="pi pi-plus" @click='openGroupCreationModal' />
            </template>
            </PillMenuBar>

            <GroupSubFeed :feedType="selectedFeed" @enter-group="enterGroup" :key="selectedFeed"/>
        </div>
    </div>
    <div class='group-home-mobile' v-else>
        <TabView ref="mobileTabView" lazy :activeIndex="activeIndex">
            <TabPanel header="My Groups" >
                <GroupSubFeed :feedType="SELECTED_FEED['MY_GROUPS']" @enter-group="enterGroup"/>
            </TabPanel>
            <TabPanel header="Discover">
                <GroupSubFeed :feedType="SELECTED_FEED['DISCOVER']" @enter-group="enterGroup"/>
            </TabPanel>
            <TabPanel header="About" :contentStyle="'text-align:center;'" >
                <div class="card" style="height:100vh;">
                <Button class='green-button__primary p-button-rounded' label=' What are Groups?' icon="pi pi-video" iconPos="right" @click='showVideo' />
                </div>
            </TabPanel>
        </TabView>
        <a class="new-group-floating-btn p-d-md-none" @click="openGroupCreationModal">
            <i class="pi pi-plus" style="font-size: 1.5rem"></i>
        </a>
    </div>

    <GroupSignupProcess ref='groupSignupProcessModal'/>
    <GroupInvitesModal ref='groupInvitesModal' />
    <JoinGroupModal ref='joinGroupModal'/>
    <ViewMediaModal ref="videoPlayer"/>
</template>

<script>
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

import PillMenuBar from '../../menu/PillMenuBar.vue';
import GroupInvitesModal from '../modal/GroupInvitesModal.vue';
import GroupSubFeed from '../GroupSubFeed.vue';
import GroupSignupProcess from '../modal/GroupSignupProcess.vue';
// import GroupInvitesList from '../GroupInvitesList.vue';
import JoinGroupModal from '../modal/JoinGroupModal.vue';

import GroupService from '../../../service/GroupService';
import {SELECTED_FEED, excludeDefaultInvrsGroup} from './../';
import FeedService from '../../../service/FeedService';
import BrowserUtils from '../../../utilities/BrowserUtils';

import EventBus from '../../../event-bus';
import ViewMediaModal from '../../modal/ViewMediaModal.vue';

export default {
    name: 'GroupHome',
    components: {
        GroupSignupProcess, GroupSubFeed, PillMenuBar, GroupInvitesModal, TabView, TabPanel,  JoinGroupModal,
        ViewMediaModal
    },

    data() {
        return {
            // CONSTANTS
            SELECTED_FEED,

            selectedFeed: SELECTED_FEED['MY_GROUPS'],
            groups: [],
            myGroupsDiscoverMenuItems: [
                { label: 'My Groups', action: this.viewMyGroupsFeed, selected: false },
                { label: 'Discover', action: this.viewDiscoverFeed, selected: false },
            ],

            inviteCount: 0,
            pillMenuBarKey: 0,
            loading: false,

            browserUtils: BrowserUtils,
            rightGridPanelHeight: '',
            rightGridEl: null,
            ro: null,

            activeIndex: 0
        };
    },

    computed: {
        // invitesHeaderMobile() {
        //     return this.inviteCount == 0 ? 'Invites' :'Invites ' + this.inviteCount;
        // },
        inviteCountBadge() {
            return ''+this.inviteCount;
        },
        invitesHeaderDisabled() {
            return this.inviteCount == 0;
        }
    },

    watch: {
        inviteCount(){
            this.updateInvitesCountBadge();
        },
        $route (to, from){
            if(from.path.includes('/groups') && to.fullPath == '/groups?create=true'){
                this.$router.push('/groups');
                this.openGroupCreationModal();
            }
        }
    },

    mounted() {
       // console.log("GroupHome mounted");

        EventBus.off("open-join-group-modal");
        EventBus.on("open-join-group-modal", this.openJoinGroupModal);

        if(this.$route.fullPath == '/groups?create=true'){
            this.$router.push('/groups');
            this.openGroupCreationModal();
        }
        
        // EventBus.off('invites-updated');
        // EventBus.on('invites-updated', this.refreshInvites);
        // this.refreshInvites();

        if (!this.browserUtils.isMobile()) {
            this.initObserver();
            GroupService.getMyGroups(0, 2, '').then((resp => {
                if( resp.data.status == "success") {

                    const arr = resp.data.groups.filter(excludeDefaultInvrsGroup);

                    if( this.$store.state.lastSelectedGroupMenu == 'DISCOVER') {
                        this.myGroupsDiscoverMenuItems[1].selected = true;
                        this.viewDiscoverFeed();
                    }
                    else if (arr.length > 0) {
                        this.myGroupsDiscoverMenuItems[0].selected = true;
                        this.viewMyGroupsFeed();
                    } else {
                        this.myGroupsDiscoverMenuItems[1].selected = true;
                        this.viewDiscoverFeed();
                    }
                    EventBus.emit("track-group-number", resp.data.totalCount);
                }
            }));
            

            // GroupService.getReceivedGroupInvites(10,0, true).then(resp=> {
            //     if( resp.data.status ==="success") {
                
            //         this.inviteCount = resp.data.totalCount;
            //     }
            //     else {
            //         console.log("error getting recieved group invites "+resp.data.message);
            //     }
            // });

            this.loading = false;

        } else {
            GroupService.getMyGroups(0, 2, '').then((resp => {
                if( resp.data.status == "success") {

                    const arr = resp.data.groups.filter(excludeDefaultInvrsGroup);

                    if (arr.length > 0) {
                        this.activeIndex = 0;
                    } else {
                        this.activeIndex = 1;
                    }
                }
            }));

            //this.updateInvitesCountBadge();
        }

    },
    unmounted() {
       // console.log("GroupHome unmounted");

        if (this.ro) {
            this.ro.unobserve(this.rightGridEl);
        }
        
    },

    methods: {

        showVideo() {
            this.$refs.videoPlayer.open('video/mp4', 'https://docs.invrs.com/public/groups.mp4');
        },

        initObserver() {
            this.rightGridEl = document.querySelector('.group-home-right-panel');
            if( this.rightGridEl ) {
                this.ro = new ResizeObserver(this.getRightGridPanelHeight).observe(this.rightGridEl);
            }
        },
        getRightGridPanelHeight() {
            this.rightGridPanelHeight = `${this.rightGridEl.clientHeight}px`;
        },

        // refreshInvites() {
        //     GroupService.getReceivedGroupInvites(10,0, true).then(resp=> {
        //         if( resp.data.status ==="success") {

        //             this.inviteCount = resp.data.totalCount;

        //             if (this.inviteCount == 0 && this.$refs.mobileTabView && this.$refs.mobileTabView.d_activeIndex) {
        //                 this.activeIndex = 0;
        //                 this.$refs.mobileTabView.d_activeIndex = 0;
        //             }
        //         }
        //         else {
        //             console.log("error getting recieved group invites "+resp.data.message);
        //         }
        //     });
        // },

        openGroupInvitesModal() {
            this.$refs.groupInvitesModal.open();
        },
        openGroupCreationModal() {
            // console.log("openGroupCreationModal");
            if(!this.$refs.groupSignupProcessModal.isOpened()){
                this.$refs.groupSignupProcessModal.open();
            }
        },
        openJoinGroupModal(data) {
            this.$refs.joinGroupModal.open(data.group, data.joinGroupFunc);
        },
        enterGroup(group) {
            this.$store.commit('SET_SELECTED_GROUP', group);
            this.$router.push('/groups/profile/' + group.groupId);
        },

        selectFeed(label) {
            this.selectedFeed = SELECTED_FEED[label];
            this.$store.commit('SET_LAST_SELECTED_GROUP_MENU', label);
        },
        viewMyGroupsFeed() {
            this.selectFeed('MY_GROUPS');
        },
        viewDiscoverFeed() {
            this.selectFeed('DISCOVER');
        },
        loadGroupPosts(currentFeedItems) {
            if( !this.loading ) {
                return FeedService.getGroupActivities(null, currentFeedItems.length);

            }
            else {
                return [];
            }

            
        },
        updateInvitesCountBadge(){
            if (this.browserUtils.isMobile()) {
                setTimeout(()=>{
                    const invitesCountHeader = document.querySelectorAll(".p-tabview-nav li:nth-child(3) a")[0];
                    if(invitesCountHeader) {
                        const ttt = document.querySelectorAll(".p-tabview-nav li:nth-child(3) a .invites-count-badge")[0];
                        ttt?.remove();
                        if(this.inviteCount > 0){
                            const countSpan = document.createElement('span');
                            countSpan.className = "invites-count-badge";
                            countSpan.innerHTML = this.inviteCount;
                            invitesCountHeader.appendChild(countSpan);
                        }
                    }
                }, 100);
            } else {
                this.pillMenuBarKey += 1;
            }
        },
    },
}
</script>
<style scoped>
.group-home-right-panel {
    margin-top: 16px;
}
.p-grid {
    margin: 0px;
    padding: 0px;
}
.p-grid > :first-child {
    padding: 0px 24px 0px 0px;
}
.p-grid > :last-child {
    padding: 0px 0px 0px 24px;
}

::v-deep(.p-scrollpanel-content) {
    padding: 0 8px;
}

.card {
    padding: 16px;
}

.status-editor-card {
    padding: 16px 0px 0px;
}

/* .header-text {
    color: #32364e;
    font-size: 24px;
    font-weight: bold;
} */

.green_button__primary {
    color: #FFFFFF;
    background: #33CC99;
    border-radius: 8px;
    border: 1px solid #33CC99;
}
.green-button__primary:hover {
    background: #00C083;
    border-color: #00C083;
}
.green-button__primary:focus {
    box-shadow: none;
    border-color: #00C083;
}

.green_button__secondary {
    color: #32364e;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #33CC99;
}
.green-button__secondary:hover {
    background: #33CC99;
    border-color: #33CC99;
}
.green-button__secondary:focus {
    box-shadow: none;
    border-color: #33CC99;
}

.blue-button {
    background: #FFFFFF;
    color: #32364e;
    border: 1px solid #32364e;
}
.blue-button:hover {
    background: #32364e;
    color: #FFFFFF;
}
.blue-button:focus {
    box-shadow: none;
    border-color: #32364e;
}

::v-deep(.pill-menu-bar.sub-header .navigation) {
    margin-left: 0;
}

/* .group-actions-container {
    display: flex;
    flex-direction: column;
} */
/* .group-actions-container .header-text {
    color: black;
} */
/* .group-actions-container button {
    font-size: 16px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
} */
/* .group-actions-container > * {
    margin-bottom: 16px;
}
.group-actions-container > *:last-child {
    margin-bottom: 0px;
} */

::v-deep(.p-tabview .p-tabview-panels) {
    border: none;
    color: none;
    padding: 0px;
}
::v-deep(.p-tabview .p-tabview-nav li) {
    width: 50%;
}
::v-deep(.p-tabview .p-tabview-nav li .p-tabview-nav-link) {
    border-color: #BFBFBF;
    border: solid #BFBFBF;
    border-width: 0 0 1px 0;
    color: #32364e;
    padding: 16px;
    border-radius: 0px;
    justify-content: center;
    margin: 0 0 -1px 0;
}
::v-deep(.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link),
::v-deep(.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link) {
    background: #32364e;
    color: #FFFFFF;
    border-color: #32364e;
}
::v-deep(.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus) {
    box-shadow: none;
}

::v-deep(.invite-count-badge) {
    background: #e63e3e !important;
    color: white !important;
}

.new-group-floating-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 3.5rem;
    height: 3rem;
    border-radius: 20%;
    color: #ffffff;
    background: #32364e;
    right: -.5rem;
    bottom: 8vh;
    cursor: pointer;
    transition: background-color 0.2s;
    box-shadow: -0.25rem 0 1rem rgb(0 0 0 / 15%);
    z-index: 99;
}

@media (max-width: 760px) {
    ::v-deep(.p-scrollpanel-content) {
        padding: 0;
    }
    ::v-deep(.invites-count-badge) {
        background: #e63e3e !important;
        color: white !important;
        margin-left: 0.5rem;
        min-width: 1rem;
        height: 1rem;
        border-radius: 50%;
        font-size: 0.80rem;
        display: inline-block;
        text-align: center;
    }
    ::v-deep(.p-tabview-nav-link) {
        height: 33px;
    }
}
</style>