<template>
    <div>
        <LoadingSpinner :showLoading="initLoading"/>
        <infinite-scroll @infinite-scroll="loadMoreInvites" message="" :noResult="noMoreInvites" :style='scrollStyle' v-if='!initLoading'>
            <GroupListItem :mobileStyle="true" v-for="invite in groupInvites" :key='invite.groupInviteId' :avatar='(invite.groupAvatar? invite.groupAvatar: "")' :headerText='invite.groupName' :subText='""'>
                <template #topContent>
                    <span class='invitee-text'>{{invite.inviterName}} invited you to:</span>
                </template>
                <template #actions>
                    <Button class='accept-request-button' @click='acceptInvite($event, invite)' :label="(invite.acceptanceCount > 0 )? 'Accepted' : 'Accept'" :style="(invite.expiryDate )? 'display:none;' : ''"/>
                    <Button class='decline-request-button' @click='declineInvite($event, invite)' :label="(invite.expiryDate )? 'Declined' : 'Decline'" :style="(invite.acceptanceCount > 0 )? 'display:none;' : ''" />
                </template>
            </GroupListItem>
            <LoadingSpinner :showLoading="loading" minHeight='100px'/>
        </infinite-scroll>
    </div>
</template>

<script>

import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue';
import GroupListItem from './GroupListItem.vue'
import LoadingSpinner from '../common/LoadingSpinner.vue';

import GroupService from '../../service/GroupService';
import {USER_TYPES} from '../../common/constants';

import EventBus from '../../event-bus';

export default {
    name: 'GroupInvitesList',
    components: {
        InfiniteScroll, GroupListItem, LoadingSpinner
    },
    data() {
        return {
            //CONSTANTS
            USER_TYPES,

            loading: false,
            initLoading: false,
            groupInvites: [],
            noMoreInvites: false,
            scrollStyle: "width: 100%; max-height: 100%; height: 100%;"
        }
    },

    mounted() {
        this.initLoadInvites();
    },

    methods: {
        initLoadInvites() {
            this.initLoading = true;
            this.groupInvites = [];
            this.fetchInvites(10, 0);
        },

        async loadMoreInvites() {
            if (!this.loading) {
                this.loading = true;

                if (!this.noMoreInvites) {
                    this.fetchInvites(10, this.groupInvites.length);
                } else {
                    this.loading = false;
                }
            }
        },

        fetchInvites(limit, offset) {
            GroupService.getReceivedGroupInvites(limit, offset, true).then( resp => {
                if (this.initLoading) {
                    this.initLoading = false;
                }

                this.loading = false;

                const invites = resp.data.invites;

                if (invites.length > 0) {
                    const results = this.groupInvites.concat(invites);

                    this.groupInvites = results;

                    this.noMoreInvites = false;
                } else {
                    this.noMoreInvites = true;
                }
            });
        },

        acceptInvite(e, groupInvite) {
            e.stopPropagation();
            
           
            console.log("accepting invite for groupInvite "+ groupInvite.groupInviteId);
            GroupService.acceptGroupInvite(groupInvite.groupInviteId).then(resp=> {
                if( resp.data.status === "success") {
                   
                    this.$toast.add({severity:'success', summary: 'You have successfully joined '+groupInvite.groupName, detail:'', life: 3000, group: 'tc'});
                    this.$store.commit('MARK_CHANNELS_STALE');
                    EventBus.emit('refresh-group-list');
                    EventBus.emit('refresh-app-group-menu');
                    this.groupInvites = [];
                    this.fetchInvites(10, 0);
                    EventBus.emit('invites-updated');
                }
                else {
                    console.log("Error accepting invite."+ resp.data.message);

                    if(resp.data.message && resp.data.message.startsWith("Team members limit is")){
                        this.$toast.add({severity:'info', summary: "Oops! This team is full with 20 members. Why don't you start your own?", detail:'', life: 4000, group: 'center'});
                    }
                }
            });
           
        },

        declineInvite(e, groupInvite) {
            e.stopPropagation();
            

            console.log("declining invite for groupInvite "+ groupInvite.groupInviteId);
            GroupService.declineGroupInvite(groupInvite.groupInviteId).then(resp=> {
                if( resp.data.status === "success") {
                   //
                    EventBus.emit('refresh-group-list');
                    this.groupInvites = [];
                    this.fetchInvites(10, 0);
                    EventBus.emit('invites-updated');
                }
                else {
                    console.log("Error declining invite."+ resp.data.message);
                }
            });
           
        },
    }
}
</script>

<style scoped>
*:not(.pi), ::v-deep(.p-component) {
    font-family: "Trebuchet MS", "Verdana";
}

.invitee-text {
    color: black;
    font-size: 12px;
}

::v-deep(.list-item-container) {
    border-bottom: 1px solid #BFBFBF;

}
::v-deep(.list-item-container:last-child) {
    border-bottom: none;
}

.buttons-container .p-button {
    border: 1px solid;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background: #FFFFFF;
    color: #32364e;
}
.buttons-container .p-button:hover {
    color: #FFFFFF;
}
.buttons-container .accept-request-button {
    border-color: #33CC99;
}
.buttons-container .accept-request-button:hover {
    background: #33CC99;
    border-color: #33CC99;
}
.buttons-container .accept-request-button:focus {
    box-shadow: none;
    border-color: #33CC99;
}
.buttons-container .decline-request-button {
    border-color: #E63E3E;
}
.buttons-container .decline-request-button:hover {
    background: #E63E3E;
    border-color: #E63E3E;
}
.buttons-container .decline-request-button:focus {
    box-shadow: none;
    border-color: #E63E3E;
}
</style>