<template>
    <div :class='listItemContainerClass' v-if="isMobile() || mobileStyle">
        <div class='top-container' >
            <div class='avatar-container'>
                <UserAvatar :imageSrc="avatar ? avatar: generateGroupAvatar(headerText)" size='lg' shape='roundedSquare'/>
                <Badge :value="getGroupNotificationCount()" class='list-item-badge' v-if="getGroupNotificationCount()"></Badge>
            </div>
            <div class='text-container'>
                <span class='top-content' v-if="$slots.topContent">
                    <slot name="topContent"></slot>
                </span>
                <span class='header-text'>
                    {{headerText}}
                </span>
                <span class='sub-text-2' v-if="subText2">
                    {{subText2}}
                </span>
            </div>
            <div class='buttons-container' v-if="$slots.actions">
                <slot name="actions"></slot>
            </div>
        </div>
        <span class='sub-text' v-if="subText">
            {{subText}}
        </span>
    </div>
    <div :class='listItemContainerClass' v-else style="justify-content: space-between;">
        <div class='top-container'>
            <UserAvatar :imageSrc="avatar ? avatar: generateGroupAvatar(headerText)" size='lg' shape='roundedSquare'/>
            <div class='buttons-container' v-if="$slots.actions">
                <slot name="actions"></slot>
            </div>
        </div>
        <div class='text-container'>
            <span class='top-content' v-if="$slots.topContent">
                <slot name="topContent"></slot>
            </span>
            <span class='header-text'>
                {{headerText}}
            </span>
            <span class='sub-text' v-if="subText" style="text-overflow: ellipsis;overflow: hidden;display: -webkit-box; -webkit-line-clamp: 7; -webkit-box-orient: vertical;">
                {{subText}}
            </span>
            
        </div>
        <!-- <div class='text-container'> -->
            <span class='sub-text-2'>
               {{subText2}}
            </span>
        <!-- </div> -->
    </div>
</template>

<script>
import UserAvatar from '../profile/UserAvatar.vue';
import Badge from 'primevue/badge';

import { generateGroupAvatar } from './';
import BrowserUtils from '../../utilities/BrowserUtils';

import { mapState } from 'vuex';

export default {
    name: 'GroupListItem',
    props: {
        headerText: {
            type: String, 
            required: true
        },
        subText: {
            type: String,
            required: true
        },
         subText2: {
            type: String,
            required: false
        },
        avatar: {
            type: String,
            required: true
        },
        mobileStyle: {
            type: Boolean,
            default: false
        },
        groupId: {
            type: Number
        }
    },
    components: {
        UserAvatar, Badge
    },
    computed: {
        ...mapState(['feedNotificationCounts']),
        ...mapState(['groupChannelsUnreadCounts']),
        listItemContainerClass() {
            return {
                'list-item-container': true,
                'card': true,
                'mobile-style': this.mobileStyle
            }
        }
    },
    methods:{
        generateGroupAvatar,

        isMobile() {
            return BrowserUtils.isMobile();
        },

        getGroupNotificationCount(){
           if(this.groupId){
                let count = this.groupChannelsUnreadCounts[this.groupId]?.unreadCount 
                    ? this.groupChannelsUnreadCounts[this.groupId].unreadCount : 0;
                count += this.feedNotificationCounts?.unseenGroupNotifications[this.groupId]?.allCount 
                    ? this.feedNotificationCounts.unseenGroupNotifications[this.groupId].allCount : 0;

                if(count > 10){
                    return "10+";
                }
                // console.log("GroupListItem getGroupsNotificationCount", 
                //    this.groupChannelsUnreadCounts?.unreadCount,  this.feedNotificationCounts?.unseen?.numGroupPosts);
                return count;
            } else {
                return 0;
            }
        },
    }
}
</script>

<style scoped>
.list-item-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 16px;
    cursor: pointer;
}

.list-item-badge {
    position: absolute;
    right: 6px;
    top: -6px;
    line-height: 1.6rem;
    background: #e63e3e;
}
.avatar-container {
    position: relative;
}

.card.mobile-style {
    box-shadow: none;
    border-radius: 0;
}
.card:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
}
.card.mobile-style:hover {
    box-shadow: none;
    transition: none;
}

.card {
    margin-bottom: 0 !important;
}

::v-deep(.p-avatar) {
    margin-right: 16px;
    min-width: 60px;
}

.text-container {
    display: flex;
    height: 100%;
    margin-top: 8px;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
}

.header-text {
    font-size: 16px;
    color: #32364e;
    font-weight: bold;
    margin-bottom: 4px;
}
.sub-text-2 {
    font-size: 12px;
    color: #BFBFBF;
    margin-top: 16px;
}

.buttons-container {
    margin-left: auto;
}
.buttons-container > ::v-deep(*) {
    margin-right: 8px;
}
.buttons-container > ::v-deep(*:last-child) {
    margin-right: 0px;
}

.top-container {
    display: flex;
}

@media (max-width: 760px) {
 
    .sub-text {
        margin-top: 8px;
    } 

    .sub-text-2 {
        margin-top: 0;
    }

    .top-container {
        align-items: center;
    }

    .text-container {
        margin-top: 0;
    }

    .card {
        border-radius: 0px;
    }

    .buttons-container {
        display: flex;
        flex-direction: column;
    }

    ::v-deep(.buttons-container .p-button) {
        width: 100% !important;
        margin-bottom: 8px !important;
        margin-right: 0 !important;
    }

 
} 
</style>