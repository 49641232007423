<template>
    <div :class='isGroupHome ? "tab-div" : "tab-div-small-margin"'>
        <GroupHome v-if="isGroupHome"/>
        <GroupProfile v-else-if="$route.fullPath.startsWith('/groups/profile')"/>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import GroupHome from '../components/groups/pages/GroupHome.vue';
// import GroupProfile from '../components/groups/pages/GroupProfile.vue';

import GroupService from '../service/GroupService';
import EventBus from '../event-bus';

export default {
    name: 'Groups',
    components: {
        GroupHome, 
        GroupProfile : defineAsyncComponent(() =>
            import('../components/groups/pages/GroupProfile.vue')
        )
    },
    data() {
        return {
            activetab: 0,
            menu: [
                {
                    label: 'Home',
                    icon: 'pi pi-fw pi-home',
                    action: this.viewHomeFeed,
                },
                {
                    label: 'Markets',
                    icon: 'pi pi-fw pi-dollar',
                    action: this.viewMarketsFeed,
                },
                {
                    label: 'Discover',
                    icon: 'pi pi-fw pi-search',
                    action: this.viewDiscoverFeed,
                },                
                {
                    label: "Groups", 
                    icon: "pi pi-fw pi-users",
                    action: () => {
                        this.$router.push({path: '/groups'});
                        this.activetab = 3;
                    },
                    disabled: !GroupService.groupsEnabled()
                }
            ],
        }
    },

    computed:{
        isGroupHome(){
            return this.$route.fullPath == '/groups' || this.$route.fullPath == '/groups?create=true';
        }
    },

   
    
    methods: {
        viewMarketsFeed() {
            this.$router.push({ path: '/home' });
            EventBus.emit('viewMarkets');
            this.activetab = 1;
        },
        viewDiscoverFeed() {
            this.$router.push({ path: '/home' });
            EventBus.emit('viewDiscover');
            this.activetab = 2;
        },
        viewHomeFeed() {
            this.$router.push({ path: '/home' });
            EventBus.emit('viewHome');
            this.activetab = 0;
        },
    }
};
</script>

<style scoped>
*:not(.pi), ::v-deep(.p-component) {
    font-family: "Trebuchet MS", "Verdana";
}

.tab-div {
    padding: 24px 0px;
}

.tab-div-small-margin {
    margin: 0px 16px;
   
}

::v-deep(.tab-menu) {
    position: fixed;
    bottom: 0;
    left: 0;
    /* height: 10vh; */
    width: 100%;
    z-index: 99;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

::v-deep(.tab-menu .p-tabmenu-nav) {
    height: 100%;
    display: flex;
}

::v-deep(.tab-menu .p-tabmenuitem) {
    width: 33.33%;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}

::v-deep(.tab-menu .p-tabmenuitem > button) {
    display: flex;
    height: 100%;
    width: 100%;
    border: 0;
    color: #32364E;
    font-family: 'Trebuchet MS', 'Verdana';
    background: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
::v-deep(.tab-menu .p-highlight > button) {
    color: #33cc99;
}

@media (max-width: 760px) {
    .tab-div {
        padding: 0px;
    }

    .tab-div-small-margin {
        margin: 0px;
        position: fixed;
        width: 100%;
    }
}
</style>