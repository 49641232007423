<template>
    <Dialog header="Join group" v-model:visible="display" :modal="true" class='join-group-modal' :draggable='false'>
        <div class="container">
            <p>Please note, this team has group portfolios enabled and has made sharing at least one account mandatory.  
                Your shared account holdings are combined  with other members' shared accounts to create a Group Portfolio with aggregated returns and holdings.  
                Only holdings and percentage returns are shared with other team members.  
                If you choose not to share at least one account the team owner may remove you from the team.</p>
        </div>

        <template #footer>
            <Button class='join-button' label='Join' @click='joinGroup()'/>
            <Button class='cancel-button' label='Cancel' @click='cancel()'/>
        </template>
    </Dialog>

</template>

<script>
import Dialog from 'primevue/dialog';

// import GroupService from '../../../service/GroupService';

// import EventBus from '../../../event-bus';

export default {
    name: 'JoinGroupModal',
    data() {
        return {
            display: false,
            joinGroupFunc: null,
            group: null,
        }
    },
    components: {
        Dialog, 
    },

    methods: {
        open(group, joinGroupFunc) {
            console.log("JoinGroupModal", group, joinGroupFunc);
            this.group = group;
            this.joinGroupFunc = joinGroupFunc;
            this.display = true;
        },

        cancel() {
            this.display = false;
        },

        joinGroup() {
            this.display = false;

            this.joinGroupFunc();
        },

    },
}
</script>

<style>

.join-group-modal {
    position: static;
    font-family: "Trebuchet MS", "Verdana";
    width: 600px;
    border-radius: 16px;
}

.join-group-modal .p-dialog-header {
    padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.join-group-modal .p-dialog-header .p-dialog-title {
    font-size: 24px;
    font-weight: bold;
}

.join-group-modal .p-dialog-content {
    height: 100%;
    overflow-y: hidden;
    border-top: 1px solid #BFBFBF;
    border-bottom: 1px solid #BFBFBF;
    padding: 24px 16px;
}

.join-group-modal .p-dialog-footer {
    text-align: center;
    padding: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}
</style>
<style scoped>
.container {
    display: flex;
    flex-direction: column;
}

</style>